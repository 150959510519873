import React from "react";
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '../../assets/assets'
// import './CustomerServiceLink.css'

function CustomerServiceLink() {
    return (
        <div className="social-link ServiceLink">
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="social-link-heading">
                            <h3 className="mb-0">Best-In-Class</h3>
                            <h4>Support, Scalability, Security</h4>
                            <p>FLO.ink is the world’s leading link management platform for customer service teams</p>
                            <button className="btn brown">Get A Quote</button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <ul className='ps-0 markiting-list'>
                            <li className='markiting-content d-sm-flex align-items-center'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialNine} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Onboarding</strong>
                                    <p className='mb-0'>Get the most out of FLO.ink with the help of our robust knowledge base to maximize your customer relationships.</p>
                                </div>
                            </li>
                            <li className='markiting-content d-sm-flex align-items-center'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialFour} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Reliable API & Integration</strong>
                                    <p className='mb-0'>Improve your customer engagement with real-time and personalized communication at scale.</p>
                                </div>
                            </li>
                            <li className='markiting-content d-sm-flex align-items-center mb-0'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialEleven} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Robust security</strong>
                                    <p className='mb-0'>FLO.ink follows the best industry practices in security solutions, including two-factor authentication & HTTPS on all links.</p>
                                </div>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default CustomerServiceLink;