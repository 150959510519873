import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './HomePageCard.css'

function HomePageCard() {
    return (
        <div className='home-page-cards'>
            <Container>
                <div className='heading-cards'>
                    <strong className='d-flex justify-content-center align-items-center'>1,253,89+ User Trust Flo.ink, and so Should You!</strong>
                </div>
                <Row>
                    <Col lg={4} md={6} sm={6}>
                        <div className='main-card pink'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className='card-img pink d-flex align-items-center justify-content-center'>
                                    <img className='img-fluid' src={Images.CardOne} alt="Site Logo" />
                                </div>
                            </div>
                            <div className='card-content'>
                                <strong className='d-flex justify-content-center'>Link Shortening</strong>
                                <p className='mb-0'>Increase Brand Exposure - Everything you need to track and manage campaigns within a single platform</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                        <div className='main-card sky'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className='card-img sky d-flex align-items-center justify-content-center'>
                                    <img className='img-fluid' src={Images.CardTwo} alt="Site Logo" />
                                </div>
                            </div>
                            <div className='card-content'>
                                <strong className='d-flex justify-content-center'>Branded Links</strong>
                                <p className='mb-0'>Start Branding Your Links - Setup multiple domains and subdomains in seconds and start sharing your branded links</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                        <div className='main-card brown mb-0'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className='card-img brown d-flex align-items-center justify-content-center'>
                                    <img className='img-fluid' src={Images.CardThree} alt="Site Logo" />
                                </div>
                            </div>
                            <div className='card-content'>
                                <strong className='d-flex justify-content-center'>Mobile Links</strong>
                                <p className='mb-0'>Create a Seamless User Experience - Direct your audience to the right destination through unique mobile communications</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}
export default HomePageCard;