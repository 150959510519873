import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Images } from '../../../assets/assets'
import '../../Auth/Auth.css'

function ForgetPassword() {
	return (
		<div className='auth-page d-flex flex-column align-items-center justify-content-center'>
			<div className='auth-container mx-auto d-flex flex-column align-items-center justify-content-center'>
				<strong className='auth-logo-strong'>
					<Link to='/' className='d-inline-block'>
						<img src={Images.siteLogo} alt='' />
					</Link>
				</strong>
				<h3 className='auth-heading'>Forgot Your Password?</h3>
				<Form className='auth-form'>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label className='auth-span'>Email</Form.Label>
						<Form.Control type="email" required />
					</Form.Group>										
					<Button className='auth-form-btn d-block' type="submit">Reset</Button>
				</Form>
				<span className='auth-separator auth-span'>OR</span>				
				<div className='switch-to-signup'>
					<span className='auth-span switiching-span'>Back To</span>
					<Link to='/SignUp'>Sign Up Now</Link>
				</div>
			</div>
		</div>
	)
}

export default ForgetPassword