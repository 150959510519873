import React from "react";
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '../../assets/assets'
import '../Solutions/Solutions.css'

function SocialMedia() {
    return (
        <div className="social-media">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <div className="social-media-heading">
                            <div className="icon-bg gray d-flex justify-content-center align-items-center">
                                <img className='img-fluid' src={Images.socialOne} alt="Site Logo" />
                            </div>
                            <h3>Social Media</h3>
                            <h4>Increase Social Brand Worth</h4>

                            <strong className="d-flex">A Shot of Innovation</strong>
                            <p>FLO.ink helps you build your personal or business brand, make influential connections and win more customers.</p>
                            <strong className="d-flex">Key Features</strong>
                            <ul className="p-0">
                                <li>
                                    <span>Link Shortening</span>
                                    <p>Create and share click-worthy links across every platform</p>
                                </li>
                                <li>
                                    <span>Branded Links</span>
                                    <p>Ensure brand building as your custom links circulate around the web</p>
                                </li>
                                <li>
                                    <span>Campaign Management & Analytics</span>
                                    <p>Identify and promote your best-performing content.</p>
                                </li>
                            </ul>
                            <div>
                                <button className='btn btn-solid me-2'>Get Started</button>
                                <button className='btn btn-outline'>Get A Quote</button>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='floink-img d-flex justify-content-md-end align-items-center'>
                            <img className='img-fluid' src={Images.floinkImg} alt="Site Logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default SocialMedia;