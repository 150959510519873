import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import PageBanner from '../PageBanner/PageBanner'
import { Images } from '../../assets/assets'
import Footer from '../Footer/Footer'

function PrivacyPolicy() {
	return (
		<div className='cms-page'>
			<Header />
			<PageBanner bannerHeading='Privacy Policy' bannerSubHeading='Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.' bannerContentImg={Images.BannerContentImg} />
			<section className='cms-text position-relative'>
				<div className='page-content-bg'>
					<img src={Images.PageContentBg} alt='' />
				</div>
				<Container>
					<h3> Flo.ink - URL Shortener operates the<Link to='/'>https://www.flo.ink/</Link> website, which provides the SERVICE.</h3>
					<p className='page-text'>This document is used to inform the website users about our policies regarding collection, use, disclosure and security of their Personal Information while using our Services.   </p>
					<p className='page-text'>Anyone who choose to use our Services need to agree with our terms of using his Personal Information in relation to this policy. Whatever information we collect from our website visitors, we use it for providing the improved Services and will not disclose this information to any third-party except as described in this document.  </p>
					<h3>Scope</h3>
					<p className='page-text'>This Privacy Policy encloses the treatment of personally identifiable information (PII) of website visitors collected by Flo.ink when they access and use the Services, or when they view or interact a Flo.ink. This Privacy Policy also covers the treatment of any of your information that Flo.ink’s customers share with us.</p>
					<p className='page-text'>This Privacy Policy does not apply to Third Party Services or practices of other businesses that Flo.ink does not own or control, including other company websites, services, features, content or applications, which you can access through other service providers, such as Facebook, Twitter, etc., or to the individuals that Flo.ink doesn’t employee or manage. Flo.ink does not take responsibility for the content, services and business policies of those third parties. We encourage all users to review the policies and terms and conditions of any Third-Party Services you access.</p>
					<h3>Information We Collect</h3>
					<p className='page-text'>Flo.ink may collect your personally identifiable information as described in this document when you;</p>
					<ol>
						<li className='page-text'>register to use the Services through your user account with Flo.ink after sign up.</li>
						<li className='page-text'>use Flo.ink Services through third-party social networking service providers (your social media “Account”)</li>
						<li className='page-text'>use the Services, view or interact with a Flo.ink Link on a third-party website.</li>
					</ol>
					<p className='page-text'>Flo.ink will collect the following information some of which might be considered personally identifiable information under applicable law.</p>
					<h3>When You Register for a NED.link Account</h3>
					<p className='page-text'>As soon as you create Flo.ink Account, the system will collect the personal information from you including your name, and email address. If you create Flo.ink Account using your social networking login details such as Facebook, Twitter and Google, the system will access your personal information as provided by your Third-Party Account (as per your privacy settings on that account), so that you can log into your Flo.ink Account with us.</p>
					<p className='page-text'>We may use your contact information to update you about our Services and communicate with you about any future updates regarding Flo.ink website, services, content, policies, and applications. Flo.ink reserves the right to contact the registered website users when necessary. For instance, we can contact you for account management and administrative purposes.</p>
					<p className='page-text'>All the content you submit while using our Services will be retained by Flo.ink and may continue to be shared by third parties, even after you terminate your account unless you request deletion of your information stored in our database as described in this Privacy Policy.</p>
					<h3>When you create a NED.link Link</h3>
					<p className='page-text'>Our Services key feature is to help users transform their lengthy URLs (uniform resource locators) into short, branded links. You can shorten the links without registering for Flo.ink account. When you create a short link, Flo.ink collects and store both the original and shortened URL, and once you logged in to your Flo.ink Account, this information will be associated with your Account.</p>
					<p className='page-text'>Flo.ink also collects and stores your IP addresses as derived from your IP location along with the time and date on which you used the Service for URL shortening. Moreover, the name of social networking platform and your username will also be stored in our database if you share a Flo.ink Link on that platform.</p>
					<h3>When you interact with NED.link Link</h3>
					<p className='page-text'>Flo.ink automatically collects and stores the information regarding your interaction with the website, including views and clicks, etc., with every Flo.ink Link created through the Services. This information includes, but not limited to:</p>
					<ol>
						<li className='page-text'>user's IP address and location  </li>
						<li className='page-text'>the referring websites or services</li>
						<li className='page-text'>the time and date of each click/access</li>
						<li className='page-text'>cookies information</li>
						<li className='page-text'>mobile advertising identifiers</li>
						<li className='page-text'>device settings (browser type, language, OS, etc)</li>
					</ol>
					<h3>Other Information Flo.ink Automatically Collects</h3>
					<p className='page-text'>As you visit the website, the system will automatically collect your IP address, referring websites, access date and time, and cookies information. This data enables us and our authorized third-parties to determine how often the visitors use website Services so that we can analyze and improve them. We also use this information to improve our customer service.</p>
					<p className='page-text'>Flo.ink may use third-party APIs and SDKs (software development kits) to provide specific functions in the Services.</p>
					<h3>Cookies and Other Tracking Technologies</h3>
					<p className='page-text'>Flo.ink use the cookies and similar technologies to administer the website by learning about user behavior, movements and analyses user trends around the website, and to collect the demographic information about website visitors. The majority of this information is collected through different tracking technologies; predominantly through cookies, web beacons and user’s browser or device (e.g., IP address, browser version, operating system, Mac address, etc.)</p>
					<p className='page-text'>The tracking technologies may be employed by the first-party or third-party when you use our Services.  Flo.ink uses cookies for the following purposes.</p>
					<ul>
						<li>
							<h3>Essential operation: <span className='page-text'> These cookies are mandatory because they allow us to to operate this website as you have requested.</span></h3>
						</li>
						<li>
							<h3>Functional:<span className='page-text'>These cookies allow us to operate and manage certain functions of this website aligned with your needs and requests to use our Services.</span></h3>

						</li>
						<li>
							<h3>Site Performance: <span className='page-text'>These cookies are necessary to analyze how the website is accessed and used by the users, and how it performs as they request for certain functionality. The information collected through these cookies help us to maintain, operate and continually improve the Flo.ink website.</span></h3>

						</li>
						<li>
							<h3>Advertising: <span className='page-text'> We may use cookies to collect data from social media platforms you use to sign up your Account with us for the purpose of tailoring our advertising strategies based on your interests and preferences on those platforms. We may use different tracking technologies to receive or collect information from Flo.ink website or elsewhere on the internet and use this data to provide measurement services and target ads.    </span></h3>
						</li>
					</ul>
					<p className='page-text'>Flo.ink uses third-party cookies including Google Analytics to analyze how user interact with the website and use Services. It helps us compile reports on our Services’ activity and usage as a result of user interaction with Flo.ink website.</p>
					<p className='page-text'>This Privacy Policy entitles the use of cookies by Flo.ink only and does not cover any third-party usage of cookies. Flo.ink does not control any Third-Party Services about when and how they place cookies on your computer; for example, when you visit a website by clicking on a Flo.ink generated short URL.  </p>
					<h3>How We Use the Collected Information</h3>
					<p className='page-text'>We use the personally identifiable information of Flo.ink users for different administrative, business and advertising purposes.  </p>
					<ul>
						<li className='page-text'>operate the website with seamless functionality.</li>
						<li className='page-text'>provide the Services as the users have requested.</li>
						<li className='page-text'>process and complete any online transactions.</li>
						<li className='page-text'>verify user's identity to comply with legal processes.</li>
						<li className='page-text'>improve the Services and personalize them to user needs.</li>
						<li className='page-text'>respond to user inquiries and provide them with technical support.</li>
						<li className='page-text'>provide users easy access to certain functionalities of our Services.</li>
						<li className='page-text'>communicate with current and prospective concerning about Services.</li>
						<li className='page-text'>monitor and analyze usage and trends of the website and Services.</li>
						<li className='page-text'>comply with the legal obligations, and enforce our terms and conditions</li>
					</ul>
					<h3>Usage Across Devices</h3>
					<p className='page-text'>Flo.ink may use the collected information to make interferences a unique user has created or interacted with Flo.ink Links on different devices so that we can detect and deter fraudulent, malicious or any unlawful activity and analyze how individuals use our Services. For instance, if a user created a Flo.ink Link on a computer connected to his residential WiFi network and then clicked it, right after creation, from a mobile device connected to same network, we may infer that a single user has created and clicked on that Flo.ink Link because both events occurred through the same IP address in the same time period.</p>
					<h3>Other Legitimate Usage</h3>
					<p className='page-text'>We also may use personally identifiable information to pursue our legitimate interests, including direct marketing, research, network and database security, and to prevent any fraudulent, malicious and unlawful activity through our system, or any other legitimate purpose disclosed to the users at the time they provide personal information.</p>
					<h3>Information We Share</h3>
					<p className='page-text'>Flo.ink Services are designed to help you share information in more compact form with others. We also provide Services to our Customers who use Flo.ink Link Metrics. As a result, we may share some of your personal information generated through your use of our Services with third parties or publicly as described below.</p>
					<h3>User Created Flo.ink Links</h3>
					<p className='page-text'>Most of your activities on and through our Services will be public by default. For instance, the original URLs you have shortened to create Flo.ink Links are publicly available.  The user-provided information may be processed and/or transferred by us in any country where we or our service providers operate as allowed by the law.</p>
					<h3>User Account Information</h3>
					<p className='page-text'>We may share your email address and other personally identifiable information associated with your Flo.ink Account if you register through email address on a domain owned by an organization, (e.g., an employer or educational institution where you have a professional email account) as per law of that country. This information may include, but not limited to the number of links you have created, with that company/institution to explore the organization’s interest in creating or managing an enterprise account or for related purposes.</p>
					<h3>User-Selected Information</h3>
					<p className='page-text'>You can share your created Flo.ink Links through Third-Part Services and also the information you elect to distribute through social networking platforms. Any information you select to share will become accessible to other users of those Third-Party Service Providers. Flo.ink does not control and is not liable for the privacy practices of these Third-Party Services.</p>
					<h3>Information Disclosed Under Business Transfers</h3>
					<p className='page-text'>We may provide or transfer Flo.ink users’ data pursuant to any acquisition, sale of company assets, business transfers or other situation where information stored in our database would be transferred to the acquirer of Flo.ink as one of our business assets. In such a case, the acquirer may continue to use this information as set forth in this policy or as otherwise allowed by law.</p>
					<h3>Information Security</h3>
					<p className='page-text'>For your security and privacy, your registered Flo.ink Account information will be protected by a password. It will help you prevent the unauthorized access to your Account so select your password appropriately, limit the access to your computer and browser and always use two-factor authentication to restrict unauthorized people from accessing your Account.</p>
					<p className='page-text'>Flo.ink uses industry-standard security processes and controls to protect the users’ personal information it collects. Since, no security measure is seamlessly perfect and impenetrable therefore Flo.ink does not guarantee the prevention of unauthorized access to your information stored by Flo.ink. However, we take all appropriate measures in accordance with applicable law to circumvent the likelihoods of information breach and misuse.</p>
					<h3>Changes to This Privacy Policy</h3>
					<p className='page-text'>Flo.ink may amend this Privacy Policy at any point of time as required. As you start using our Services you will be agreeing to be bound by this Privacy Policy and any future changes once they become effective.</p>
					<p className='page-text'>For any further inquiries or concerns, you can contact us at <Link to='/'>support@floink.co</Link> </p>
				</Container>
			</section>
			<Footer />
		</div>
	)
}

export default PrivacyPolicy