import React, { useState } from "react";
import { Container, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom"
import { Images } from '../../assets/assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import './Header.css'

function Header() {
    const [show, setShow] = useState(false);
    return (
        <div className='header'>
            <Container fluid>
                <Navbar expand="lg" className=' header-logo d-flex align-items-center justify-content-between'>
                    <div className='header-logo'>
                        <strong>
                            <Link className="navbar-brand d-flex" to="/">
                                <img className='img-fluid' src={Images.siteLogo} alt="Site Logo" />
                            </Link>
                        </strong>
                    </div>
                    <div className={`d-flex align-items-center ${show ? "show" : ""}`}>
                        <div className='button-nav d-flex align-items-center'>
                            <Link to='/login' className='btn btn-outline me-1'>
                                Login
                            </Link>
                            <Link to='/signup' className='btn btn-outline'>
                                Signup
                            </Link>
                        </div>
                        <div className='header-icon'>
                            <button onClick={() => setShow(!show)}>
                                <FontAwesomeIcon icon={faBarsStaggered} />
                            </button>
                        </div>
                        <div className="toggle-switch">
                            <ul className='list-unstyled p-0 mb-0 list-toggle d-flex align-items-center'>
                                <li>
                                    <Link to='/why-flo-ink'>Why Flo.ink?</Link>
                                </li>
                                <li>
                                    <Link to='/solutions'>Solutions</Link>
                                </li>
                                <li>
                                    <Link to='/features'>Features</Link>
                                </li>
                                <li>
                                    <Link to='/pricing'>Pricing</Link>
                                </li>
                                <li>
                                    <Link to='/resources'>Resources</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Navbar>
            </Container>
        </div>
    );
}
export default Header;