import React from "react";
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '../../assets/assets'
import '../Solutions/Solutions.css'

function SocialLink() {
    return (
        <div className="social-link">
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="social-link-heading">
                            <h3><strong className="d-flex">Dynamic Link</strong> Management Platform</h3>
                            <p>Get unprecedented control to optimize, manage and track your links</p>
                            <button className="btn gray">Get A Quote</button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <ul className='ps-0 markiting-list'>
                            <li className='markiting-content d-sm-flex align-items-center'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialFive} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Links Management</strong>
                                    <p className='mb-0'>Monitor, modify and manage your links in real-time with FLO.ink. It allows bulk link tagging and filtering to categorize the links.</p>
                                </div>
                            </li>
                            <li className='markiting-content d-sm-flex align-items-center'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialSix} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Custom Links</strong>
                                    <p className='mb-0'>FLO.ink integrates into your daily workflow to help you extract more value from brandable custom short URLs with intelligent link routing.</p>
                                </div>
                            </li>
                            <li className='markiting-content d-sm-flex align-items-center mb-0'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialSaven} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Data Analytics</strong>
                                    <p className='mb-0'>Derive meaningful business insights with real-time, actionable analytics. Make better decisions based on your links data.</p>
                                </div>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SocialLink;
