import React from "react";
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '../../assets/assets'
// import './DigitalLink.css'

function DigitalLink() {
    return (
        <div className="social-link digital">
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="social-link-heading">
                            <h3>Superlative<strong className="d-flex">Experience</strong><strong className="d-flex blue">Reliability</strong></h3>
                            <p>FLO.ink is the world’s leading link management and tracking platform for digital marketers</p>
                            <button className="btn sky">Get A Quote</button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <ul className='ps-0 markiting-list'>
                            <li className='markiting-content d-sm-flex align-items-center'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialFive} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Seamless Integrations</strong>
                                    <p className='mb-0'>FLO.ink integrates with numerous industry-leading marketing platforms and tools for better results.</p>
                                </div>
                            </li>
                            <li className='markiting-content d-sm-flex align-items-center'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialEight} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Secure Connections</strong>
                                    <p className='mb-0'>FLO.ink is built on the industry security standards. Our system has HTTPS protocol and data encryption.</p>
                                </div>
                            </li>
                            <li className='markiting-content d-sm-flex align-items-center mb-0'>
                                <div className='social-holder d-flex justify-content-center align-items-center'>
                                    <img className='img-fluid' src={Images.socialSaven} alt="Site Logo" />
                                </div>
                                <div className='social-content'>
                                    <strong className='d-flex'>Actionable Analytics</strong>
                                    <p className='mb-0'>Advanced, predictive analytics help measure and analyze your link performance and campaigns metrics.</p>
                                </div>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default DigitalLink;