import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './Floink.css'

function Floink() {
    return (
        <div className='floink'>
            <Container>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <div className='main-floink'>
                            <strong className='d-flex'>FLO.ink</strong>
                            <span className='d-flex'> Go Beyond What’s Possible</span>
                            <div className='content'>
                                <p>Create, monitor, manage secure and reliable links. Every link you create with FLO.ink, increases your brand engagement and exposure.
                                    You can share your branded links to get more clicks; making your audience more likely to engage with your brand and its content.
                                </p>
                                <p>This flexible URL shortening platform brings you value-based solutions where you can create a seamless user experience while tracking and
                                    optimizing every touchpoint.
                                </p>
                            </div>
                            <div>
                                <button className='btn btn-solid me-2'>Free Sign Up</button>
                                <button className='btn btn-outline'>Get Enterprise</button>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='floink-img d-flex justify-content-md-end align-items-center'>
                            <img className='img-fluid' src={Images.floinkImg} alt="Site Logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default Floink;