import React from 'react'
import Header from "../Header/Header"
import PageBanner from '../PageBanner/PageBanner'
import SocialMedia from '../SocialMedia/SocialMedia'
import SocialLink from '../SocialLink/SocialLink'
import DigitalMarketing from '../DigitalMarketing/DigitalMarketing'
import DigitalLink from '../DigitalLink/DigitalLink'
import CustomerService from '../CustomerService/CustomerService'
import CustomerServiceLink from '../CustomerServiceLink/CustomerServiceLink'
import { Images } from '../../assets/assets'
import Footer from '../Footer/Footer'
 
function Solutions() {
    return (
        <div className='main-bg'>
            <Header />
            <PageBanner bannerHeading='Privacy Policy' bannerSubHeading='Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.' bannerContentImg={Images.BannerContentImg} />
            <SocialMedia />
            <SocialLink />
            <DigitalMarketing />
            <DigitalLink />
            <CustomerService />
            <CustomerServiceLink />
            <Footer />
        </div>
    );
}
export default Solutions;