import React from 'react'
import './CmsCard.css'

function CmsCard(props) {
	return (
		<article className='cms-article'>
			<h3>{props.cardTitle}</h3>
			<p className='page-text'>{props.cardDescription}</p>
		</article>
	)
}

export default CmsCard