import siteLogo from "../assets/images/header-logo.png"
import bannerImg from '../assets/images/banner-img.png'
import CardOne from '../assets/images/icon-card-01.png'
import CardTwo from '../assets/images/icon-card-02.png'
import CardThree from '../assets/images/icon-card-03.png'
import GoogleIcon from './images/google.svg'
import FacebookIcon from './images/facebook.svg'
import TwitterIcon from './images/twitter.svg'
import floinkImg from './images/floink-img.png'
import socialOne from './images/social-one.png'
import socialTwo from './images/social-two.png'
import socialThree from './images/social-three.png'
import socialFour from './images/social-four.png'
import socialFive from './images/social-five.png'
import socialSix from './images/social-six.png'
import socialSaven from './images/social-savan.png'
import socialEight from './images/social-eight.png'
import footerLogo from './images/footer-logo.png'
import BannerCustom from './images/custom-banner-img.png'
import DigitalImg from './images/digital-img.png'
import CustomerServiceImg from './images/custom-services-img.png'
import socialNine from './images/social-nine.png'
import socialEleven from './images/social-eleven.png'
import BannerContentImg from './images/BannerContentImg.png'
import PageContentBg from './images/PageContentBg.png'
import WhyFloInk from './images/WhyFloInk.png'

export const Images = {
    siteLogo,
    bannerImg,
    CardOne,
    CardTwo,
    CardThree,
    GoogleIcon,
    FacebookIcon,
    TwitterIcon,
    floinkImg,
    socialOne,
    socialTwo,
    socialThree,
    socialFour,
    socialFive,
    socialSix,
    socialSaven,
    socialEight,
    footerLogo,
    BannerCustom,
    DigitalImg,
    CustomerServiceImg,
    socialNine,
    socialEleven,
    BannerContentImg,
    PageContentBg,
    WhyFloInk
}