import React from "react";
import { Container, InputGroup, FloatingLabel } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';

// import { Images } from '../../assets/assets'
import './StillHaveQuestion.css'

function StillHaveQuestion() {
    return (
        <div className="still-have-question">
            <Container>
                <div className="still-question-hold">
                    <strong className="d-flex justify-content-center align-items-center">Flo.ink</strong>
                    <span className="d-flex justify-content-center align-items-center">Still Have You Question?</span>
                    <div className="content-hold">
                        <p className="text-white mb-0 text-center">Simply fill up the form below our support team contact with you within 24 hours</p>
                    </div>
                    <div className="form-hold">
                        <div className="input-hold d-md-flex align-items-center">
                            <InputGroup>
                                <Form.Control
                                    placeholder="Name"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            <InputGroup>
                                <Form.Control
                                    placeholder="Email"
                                    aria-label="email"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            <InputGroup>
                                <Form.Control
                                    placeholder="Contact"
                                    aria-label="contact"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                        </div>
                        <FloatingLabel controlId="floatingTextarea2" label="Message / Question ">
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                            />
                        </FloatingLabel>
                    </div>
                </div>
            </Container>
        </div>
    );
}
export default StillHaveQuestion;