import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './Services.css'

function Services() {
    return (
        <div className='services'>
            <Container>
                <Row>
                    <Col md={4}>
                        <div className='marketing-heading'>
                            <strong className='d-flex'>Flo.ink</strong>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className='main-marketing'>
                            <ul className='ps-0 marketing-list'>
                                <li className='marketing-content d-sm-flex align-items-center'>
                                    <div className='social-holder d-flex justify-content-center align-items-center'>
                                        <img className='img-fluid' src={Images.socialOne} alt="Site Logo" />
                                    </div>
                                    <div className='social-content'>
                                        <strong className='d-flex'>Social Media</strong>
                                        <p className='mb-0'>Make influential connections and win more customers. Create and share click-worthy links across every platform.</p>
                                    </div>
                                </li>
                                <li className='marketing-content d-sm-flex align-items-center'>
                                    <div className='social-holder d-flex justify-content-center align-items-center'>
                                        <img className='img-fluid' src={Images.socialTwo} alt="Site Logo" />
                                    </div>
                                    <div className='social-content'>
                                        <strong className='d-flex'>Digital Marketing</strong>
                                        <p className='mb-0'>Enhance click-through rates with reliable links for increased online conversions.</p>
                                    </div>
                                </li>
                                <li className='marketing-content d-sm-flex align-items-center'>
                                    <div className='social-holder d-flex justify-content-center align-items-center'>
                                        <img className='img-fluid' src={Images.socialThree} alt="Site Logo" />
                                    </div>
                                    <div className='social-content'>
                                        <strong className='d-flex'>Digital Marketing</strong>
                                        <p className='mb-0'>Equip your customer service teams with powerful business links and analytics.</p>
                                    </div>
                                </li>
                                <li className='marketing-content d-sm-flex align-items-center'>
                                    <div className='social-holder d-flex justify-content-center align-items-center'>
                                        <img className='img-fluid' src={Images.socialFour} alt="Site Logo" />
                                    </div>
                                    <div className='social-content'>
                                        <strong className='d-flex'>Reliable API & Integration</strong>
                                        <p className='mb-0'>Improve your customer engagement with real-time and personalized communication at scale.</p>
                                    </div>
                                </li>
                                <li className='marketing-content d-sm-flex align-items-center'>
                                    <div className='social-holder d-flex justify-content-center align-items-center'>
                                        <img className='img-fluid' src={Images.socialOne} alt="Site Logo" />
                                    </div>
                                    <div className='social-content'>
                                        <strong className='d-flex'>Social Media</strong>
                                        <p className='mb-0'>Make influential connections and win more customers. Create and share click-worthy links across every platform.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default Services;