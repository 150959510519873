import React from 'react'
import Header from '../Header/Header'
import { Container } from 'react-bootstrap'
import PageBanner from '../PageBanner/PageBanner'
import { Images } from '../../assets/assets'
import CmsCard from '../CmsCard/CmsCard'
import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'

function Features() {
	return (
		<div className='cms-page'>
			<Header />
			<PageBanner bannerHeading='Features' bannerSubHeading='Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.' bannerContentImg={Images.BannerContentImg} />
			<div className='cms-text'>
				<div className='cms-card-holder'>
					<Container>
						<div className='cms-heading-style'>
							<h2>Link Shortening</h2>
							<span className='page-text d-block text-center'>Low-Cost Solution to Reinforce Brand Awareness</span>
						</div>
						<p className='page-text text-center'>Increase Brand Exposure -- Everything you need to track and manage campaigns within a single platform</p>
						<CmsCard cardTitle='Multiply Click-Throughs' cardDescription='Attract more clicks and build a consistent branding impression by controlling how your links look, starting with identifiable back-halves that show where audience will be navigated after they click. Create custom domains by replacing “FLO.ink” with a domain name that perfectly fits your brand.' />
						<CmsCard cardTitle='Monitor Links Performance' cardDescription='Manage links easily and effectively. With several data points on shortened URL you create with FLO.ink, you can share more content to increase brand engagement. Easily monitor your links performance, demographics, click-throughs, and top referring platforms, etc., in one reliable interface.' />
						<CmsCard cardTitle='Shorten Endless Number of Links' cardDescription='With FLO.ink’s open source, flexible API, you can quickly shorten as many URLs as you want. Share unique URLs at scale via email or SMS. Use custom referral links to empower your brand’s top influencers. Leverage endless possibilities for advertising to your users directly. Build Secure & Reliable Links FLO.ink platform is secure, agile and incredibly flexible to power your digital and print marketing. All URLs under HTTPS with 100% private and secure click data and reporting. Intelligent data encryption guards shortened links against third-party breach and tampering.' />
						<div className='cms-card-btns d-flex justify-content-center'>
							<Link to='/' className='btn btn-solid'>Get Started</Link>
							<Link to='/' className='btn btn-outline'>Get A Quote</Link>
						</div>
					</Container>
				</div>
				<div className='gray-background cms-card-holder'>
					<Container>
						<div className='cms-heading-style'>
							<h2>Branded Links</h2>
							<span className='page-text d-block text-center'>Get Custom Domains</span>
						</div>
						<p className='page-text text-center'>Start Branding Your Links -- Setup multiple domains and subdomains in seconds and start sharing your branded links</p>
						<CmsCard cardTitle='Scale up your Clicks' cardDescription='Have full control over your content. Branded Short Domains replace “FLO.ink” in your links with the brand name of your choice. It strengthens audience trust in your shortened links they click. Claiming your domain is a good investment when clicks go up.' />
						<CmsCard cardTitle='Branded Short Domains, for Free' cardDescription='FLO.ink combines custom domain setup with intelligent link management, giving you a scalable brand-building platform. With branded short domains, smart link routing, and real-time reporting, you’ll have unprecedented control to build, optimize, manage, and track your links.' />
						<CmsCard cardTitle='Amplify Your Links CTR by 35% & More!' cardDescription='Drive CTR with branded short domains. Businesses that share custom links see an incredible boost in click through rates. Your audience is more likely to engage with your content when you give them a reason to click on your links by adding custom domains in your shortened links.' />
						<CmsCard cardTitle='Create Recognizable Shortlinks' cardDescription='Edit Short URL destination anytime. Take a step further with custom back-halves that you can edit to give your audience a preview of the destination they will be navigated after clicking the branded link. It builds trust and boosts click-through rates.' />
						<CmsCard cardTitle='Auto-Branding to Build Authority' cardDescription='Empower your marketing links by providing a strong quality signal through FLO.ink’s auto-branding about the content you share across every channel. Any shortened link using FLO.ink will be automatically converted into a branded link including your custom domain name.' />
						<div className='cms-card-btns d-flex justify-content-center'>
							<Link to='/' className='btn btn-solid'>Get Started</Link>
							<Link to='/' className='btn btn-outline'>Get A Quote</Link>
						</div>
					</Container>
				</div>
				<div className='cms-card-holder'>
					<Container>
						<div className='cms-heading-style'>
							<h2>Mobile Links</h2>
							<span className='page-text d-block text-center'>Create Meaningful Mobile Experiences</span>
						</div>
						<p className='page-text text-center'>Create a Seamless User Experience -- Direct your audience to the right destination through unique mobile communications</p>
						<CmsCard cardTitle='Deep Link URLs' cardDescription='Use FLO.ink to create and use deep links to drive users directly into a specific point within your app or to route them to the app store for download. Deep link URLs create a seamless transition to mobile, drive app engagement, and encourage app installs.' />
						<CmsCard cardTitle='QR Codes' cardDescription='Generate and download QR code with every link shortened. Engage and inform your audience with smart, secure, next-gen QR codes and drive them from print to digital experiences. FLO.ink makes it simple to create, manage and track QR codes to visualize consumer engagement.' />
						<CmsCard cardTitle='Campaign Management & Analytics' cardDescription='An infrastructure that is set up to grow with you. FLO.ink’s real-time, interactive dashboard gives you a full view of metrics like app opens, downloads, site visits across every device, platform and geolocation. It helps you determine what’s working, and where and how your audience is engaging with your brand.' />
						<CmsCard cardTitle='Powerful Integrations' cardDescription='Incorporate branded links into your existing web processes, workflows, tools, and tech stack with ease using powerful FLO.ink API. Create mobile deep links right from the tools you’re already using, like Salesforce, Hootsuite, Sprinklr, and more.' />
						<div className='cms-card-btns d-flex justify-content-center'>
							<Link to='/' className='btn btn-solid'>Get Started</Link>
							<Link to='/' className='btn btn-outline'>Get A Quote</Link>
						</div>
					</Container>
				</div>
				<div className='blue-background cms-card-holder'>
					<Container>
						<div className='cms-heading-style'>
							<h2>Campaign Management & Analytics</h2>
							<span className='page-text d-block text-center'>Track and Optimize Every Digital Initiative</span>
						</div>
						<p className='page-text text-center'>Increase Campaigns Performance for Better -- Monitor every touchpoint to customize campaigns using FLO.ink’s analytics tools</p>
						<CmsCard cardTitle='Say Goodbye to Worksheets' cardDescription='Build, schedule and share real-time reports based on the traffic your custom branded links receive. With FLO.ink, you can easily build custom links and track customers journey on each link and channel from a single, interactive dashboard. No hopping from one spreadsheet to another to check and evaluate campaign performance metrics.' />
						<CmsCard cardTitle='Capture 20+ Data Points' cardDescription='Fast, real-time visualization of high-frequency streams in web and mobile environments. FLO.ink empowers you 20+ real-time data points on every click, tap, and swipe so you’re armed with the information you need to share more of the content your audience is most excited about.' />
						<CmsCard cardTitle='Track Organic Clicks & Shares' cardDescription='Perfect when driving traffic to sites and track customer engagement pertaining to content’s organic clicks and shares. FLO.ink helps you focus your attention on key metrics that matter to analyze traffic sources and improve the efficiency of your marketing activities.' />
						<div className='cms-card-btns d-flex justify-content-center'>
							<Link to='/' className='btn btn-solid'>Get Started</Link>
							<Link to='/' className='btn btn-outline'>Get A Quote</Link>
						</div>
					</Container>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Features