import React from 'react'
import { Container, Accordion } from 'react-bootstrap';
import { Link } from "react-router-dom"
import Header from "../Header/Header"
import PageBanner from '../PageBanner/PageBanner';
import StillHaveQuestion from '../StillHaveQuestion/StillHaveQuestion';
import Footer from '../Footer/Footer'
import { Images } from '../../assets/assets'
import './Resources.css'

function Resources() {
    return (
        <div className='main-bg'>
            <Header />
            <PageBanner bannerHeading='Privacy Policy' bannerSubHeading='Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.' bannerContentImg={Images.BannerContentImg} />
            <section className='faqs'>
                <Container>
                    <div className='faqs-holder general-inquiries'>
                        <div className='heading-faqs-holder'>
                            <h3 className='blue'>General Inquiries</h3>
                        </div>
                        <div className='accordion-holder'>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is FLO.ink?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes, each FLO.ink’s paid plan includes different features and limitations. For example, the number of links you can shorten in a specific time period (usually a month), or how much data you can view and download.</p>
                                        <p>Check the subscription plans and features on our <Link to=''>pricing page</Link>. You can upgrade your paid plan any time; from pricing page or your dashboard.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How to create links with FLO.ink?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes, each FLO.ink’s paid plan includes different features and limitations. For example, the number of links you can shorten in a specific time period (usually a month), or how much data you can view and download.</p>
                                        <p>Check the subscription plans and features on our <Link to=''>pricing page</Link>. You can upgrade your paid plan any time; from pricing page or your dashboard.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Does FLO.ink offer paid subscription plans?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes, each FLO.ink’s paid plan includes different features and limitations. For example, the number of links you can shorten in a specific time period (usually a month), or how much data you can view and download.</p>
                                        <p>Check the subscription plans and features on our <Link to=''>pricing page</Link>. You can upgrade your paid plan any time; from pricing page or your dashboard.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>The FLO.ink dashboard?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes, each FLO.ink’s paid plan includes different features and limitations. For example, the number of links you can shorten in a specific time period (usually a month), or how much data you can view and download.</p>
                                        <p>Check the subscription plans and features on our <Link to=''>pricing page</Link>. You can upgrade your paid plan any time; from pricing page or your dashboard.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className='faqs-holder technical'>
                        <div className='heading-faqs-holder'>
                            <h3 className='blue'>Technical</h3>
                        </div>
                        <div className='accordion-holder'>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is a custom  domain?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You can hide/unhide the short links by selecting the ‘Edit’ button against target link and then click ‘Hide’ and ‘Confirm’ in the FLO.ink section accessible from the drop-down menu.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What is a custom link?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You can hide/unhide the short links by selecting the ‘Edit’ button against target link and then click ‘Hide’ and ‘Confirm’ in the FLO.ink section accessible from the drop-down menu.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>What characters FLO.ink supports for custom links?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You can hide/unhide the short links by selecting the ‘Edit’ button against target link and then click ‘Hide’ and ‘Confirm’ in the FLO.ink section accessible from the drop-down menu.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Can I delete my short link(s)?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You can hide/unhide the short links by selecting the ‘Edit’ button against target link and then click ‘Hide’ and ‘Confirm’ in the FLO.ink section accessible from the drop-down menu.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How do I hide/unhide my short links?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You can hide/unhide the short links by selecting the ‘Edit’ button against target link and then click ‘Hide’ and ‘Confirm’ in the FLO.ink section accessible from the drop-down menu.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className='faqs-holder others'>
                        <div className='heading-faqs-holder'>
                            <h3 className='blue'>Others</h3>
                        </div>
                        <div className='accordion-holder'>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Can I download my FLO.ink account data?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>  We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
                                        <p> Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
                                        <p> Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are organic shares?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>  We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
                                        <p> Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
                                        <p> Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Does FLO.ink ever reuse links?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>  We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
                                        <p> Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
                                        <p> Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Does FLO.ink count unique clicks?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>  We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
                                        <p> Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
                                        <p> Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How safe is FLO.ink?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>  We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
                                        <p> Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
                                        <p> Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>What is 2-factor authentication?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>  We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
                                        <p> Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
                                        <p> Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Are FLO.ink links private?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>  We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
                                        <p> Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
                                        <p> Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </Container>
            </section>
            <StillHaveQuestion />
            <Footer />
        </div>
    );
}

export default Resources;