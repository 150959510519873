import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from "./components/Home/Home"
import LogIn from "./components/Auth/LogIn/LogIn";
import ForgetPassword from "./components/Auth/ForgetPassword/ForgetPassword";
import SignUp from "./components/Auth/SignUp/SignUp";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfServices from "./components/TermsOfServices/TermsOfServices";
import Solutions from "./components/Solutions/Solutions";
import Resources from "./components/Resources/Resources";
import Features from "./components/Features/Features";
import WhyFloInk from "./components/WhyFloInk/WhyFloInk";
import './App.css';

function App() {
	return (
		<div className="wrapper">
			<BrowserRouter>
				<Routes>
					<Route path="/" exact={true} element={<Home />} />
					<Route path="/Login" exact={true} element={<LogIn/>}/>
					<Route path="/ForgetPassword" exact={true} element={<ForgetPassword />} />
					<Route path="/SignUp" exact={true} element={<SignUp/>}/>
					<Route path="/solutions" exact={true} element={<Solutions />} />
					<Route path="/resources" exact={true} element={<Resources />} />
					<Route path="/privacy-policy" exact={true} element={<PrivacyPolicy />}/>
					<Route path="/terms-of-services" exact={true} element={<TermsOfServices />}/>
					<Route path="/features" exact={true} element={<Features />} />
					<Route path="/why-flo-ink" exact={true} element={<WhyFloInk />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;