import React from "react";
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '../../assets/assets'

function CustomerService() {
    return (
        <div className="social-media">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <div className="social-media-heading">
                            <div className="icon-bg brown d-flex justify-content-center align-items-center">
                                <img className='img-fluid' src={Images.socialThree} alt="Site Logo" />
                            </div>
                            <h3>Customer Service</h3>
                            <h4>Interact with Consumers</h4>
                            <strong className="d-flex">Leverage in Quick Solutions</strong>
                            <p>Equip your customer service teams with powerful business links and analytics.</p>
                            <strong className="d-flex">Key Features</strong>
                            <ul className="p-0">
                                <li>
                                    <span>Onboarding & Setup</span>
                                    <p>Get your account up and running with the support of our experts offering personalized communication at scale.</p>
                                </li>
                                <li>
                                    <span>Account Management</span>
                                    <p>FLO.ink is a flexible system for account management. Our responsive team is always available for customer care.</p>
                                </li>
                                <li>
                                    <span>24/7 Support</span>
                                    <p>Dedicated teams to make sure you get the most out of your selected plan. Get instant support for all your queries.</p>
                                </li>
                            </ul>
                            <div>
                                <button className='btn btn-solid me-2'>Get Started</button>
                                <button className='btn btn-outline'>Get A Quote</button>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='floink-img d-flex justify-content-md-end align-items-center'>
                            <img className='img-fluid' src={Images.CustomerServiceImg} alt="Site Logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default CustomerService;