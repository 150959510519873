import React from "react";
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '../../assets/assets';

function DigitalMarketing() {
    return (
        <div className="social-media">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <div className="social-media-heading">
                            <div className="icon-bg sky d-flex justify-content-center align-items-center">
                                <img className='img-fluid' src={Images.socialTwo} alt="Site Logo" />
                            </div>
                            <h3>Digital Marketing</h3>
                            <h4>Drive Robust Engagement</h4>
                            <strong className="d-flex">Turn Links to Leads</strong>
                            <p>Enhance click-through rates with reliable links for increased online conversions.</p>
                            <strong className="d-flex">Key Features</strong>
                            <ul className="p-0">
                                <li>
                                    <span>Link Shortening</span>
                                    <p>Encourage your audience to convert into potential clients using call-to-action links on web, mobile or email, etc.</p>
                                </li>
                                <li>
                                    <span>Branded Links</span>
                                    <p>Drive your audience toward digital experiences with powerful brand integrity using custom links.</p>
                                </li>
                                <li>
                                    <span>Campaign Management & Analytics</span>
                                    <p>Track and optimize your digital initiatives to meet the unique requirements of each client.</p>
                                </li>
                            </ul>
                            <div>
                                <button className='btn btn-solid me-2'>Get Started</button>
                                <button className='btn btn-outline'>Get A Quote</button>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='floink-img d-flex justify-content-md-end align-items-center'>
                            <img className='img-fluid' src={Images.DigitalImg} alt="Site Logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DigitalMarketing;
