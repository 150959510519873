import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './PageBanner.css'
function PageBanner(props) {
	return (
		<div className='page-banner'>
			<Container>
				<Row className='align-items-center'>
					<Col lg={6}>
						<div className=''>
							<h1 className='banner-heading'>{props.bannerHeading}</h1>
							<p className='page-text'>{props.bannerSubHeading}</p>
						</div>
					</Col>
					<Col lg={6}>
						<div className='d-flex justify-content-center'>
							<div>
								<img src={props.bannerContentImg} alt='' />
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default PageBanner