import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Images } from '../../../assets/assets'
import Select from 'react-select';
import '../../Auth/Auth.css'

const countries = [
	{ value: 'usa', label: 'United States' },
	{ value: 'canada', label: 'Canada' },
	{ value: 'uk', label: 'United Kingdom' },
	{ value: 'Pakistan', label: 'Pakistan' },
	{ value: 'UAE', label: 'United Arab Emerites' },
	{ value: 'India', label: 'India' },
	{ value: 'Bangladesh', label: 'Bangladesh' },
	{ value: 'Astrailia', label: 'Astrailia' },
	{ value: 'South Africa', label: 'outh Africa' },
	{ value: 'Japan', label: 'Japan' },
	{ value: 'Germany', label: 'Germany' },
	{ value: 'France', label: 'France' },
	{ value: 'China', label: 'China' },
	{ value: 'Brazil', label: 'Brazil' },
	{ value: 'Mexico', label: 'Mexico' },
	{ value: 'Italy', label: 'Italy' },
	{ value: 'Russia', label: 'Russia' },
	{ value: 'Spain', label: 'Spain' },
	{ value: 'Argentina', label: 'Argentina' },
	{ value: 'Sweden', label: 'Sweden' },
	{ value: 'Nigeria', label: 'Nigeria' },
	{ value: 'Egypt', label: 'Egypt' },
	{ value: 'Indonesia', label: 'Indonesia' },
	{ value: 'Turkey', label: 'Turkey' },
	{ value: 'Vietnam', label: 'Vietnam' },	
	{ value: 'Saudi Arabia', label: 'Saudi Arabia' },
	{ value: 'Iran', label: 'Iran' },
	{ value: 'Kenya', label: 'Kenya' },
	{ value: 'New Zealand', label: 'New Zealand' },
	{ value: 'Netherlands', label: 'Netherlands' },
	{ value: 'Switzerland', label: 'Switzerland' },
	{ value: 'Portugal', label: 'Portugal' },
];
function SignUp() {
	const [selectedCountry, setSelectedCountry] = useState(null);

	const handleCountryChange = (selectedOption) => {
		setSelectedCountry(selectedOption);
	};
	return (
		<div className='auth-page'>
			<div className='auth-container mx-auto d-flex flex-column align-items-center justify-content-center'>
				<strong className='auth-logo-strong'>
					<Link to='/' className='d-inline-block'>
						<img src={Images.siteLogo} alt='' />
					</Link>
				</strong>
				<h3 className='auth-heading'>Create a free account</h3>
				<Form className='auth-form'>
					<Form.Group className="mb-3">
						<Form.Control type="text" placeholder="Username" required />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Control type="text" placeholder="First Name" required />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Control type="text" placeholder="Last Name" required />
					</Form.Group>
					<Form.Group>
						<Select
							value={selectedCountry}
							onChange={handleCountryChange}
							options={countries}
							placeholder="Country"
							className='mb-3'
							classNamePrefix="custom-select"
						// menuIsOpen= 'true'
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Control type="email" placeholder="Email" required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Control type="password" placeholder="Password" required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Control type="password" placeholder="Password Again" required />
					</Form.Group>
					<span className='auth-span passeord-info-span d-block'>Password should be minimum of 8 characters and must include at least : An uppercase letter, a lowercase letter, a number and a special character.</span>
					<Button className='auth-form-btn d-block' type="submit">Create Account</Button>
				</Form>
				<span className='auth-separator auth-span'>OR</span>
				<div className='signin-with'>
					<span className='signin-with-span auth-span d-block'>Sign in with:</span>
					<div className='social-sigin-in d-flex flex-wrap f'>
						<Link to='/' className='d-flex justify-content-center align-items-center'>
							<img src={Images.GoogleIcon} alt='' />
						</Link>
						<Link to='/' className='d-flex justify-content-center align-items-center'>
							<img src={Images.FacebookIcon} alt='' />
						</Link>
						<Link to='/' className='d-flex justify-content-center align-items-center'>
							<img src={Images.TwitterIcon} alt='' />
						</Link>
					</div>
				</div>
				<div className='switch-to-signup terms-and-conditions'>
					<span className='auth-span switiching-span d-block'>By creating an account, you agree to</span>
					<span className='auth-span switiching-span'>FLO.ink's <Link to='/'>Terms of Service </Link> and <Link to='/'> Privacy Policy</Link>.</span>
				</div>
				<div className='switch-to-signup '>
					<span className='auth-span switiching-span'>Already have an account? </span>
					<Link to='/Login'>Sign In</Link>
				</div>
			</div>
		</div>
	)
}

export default SignUp