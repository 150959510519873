import React from 'react'
import Header from '../Header/Header'
import CmsCard from '../CmsCard/CmsCard'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import PageBanner from '../PageBanner/PageBanner'
import { Images } from '../../assets/assets'
import Footer from '../Footer/Footer'
import './WhyFloInk.css'

function WhyFloInk() {
	return (
		<div className='cms-page'>
			<Header />
			<PageBanner bannerHeading='Why FLO.ink?' bannerSubHeading='Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.' bannerContentImg={Images.WhyFloInk} />
			<div className='cms-text'>
				<div className='cms-card-holder'>
					<Container>
						<div className='cms-heading-style'>
							<h2>FLO.ink 101</h2>
							<span className='page-text d-block text-center'>An Introduction to FLO.ink’s Features</span>
						</div>
						<div className='cms-heading-style card-heading-style'>
							<h2 className='text-left'>Best-in-Class Link Management</h2>
							<p className='page-text'>Create, track and optimize your promotion campaigns with industry-leading branded links and custom domains. FLO.ink is a powerful tool for digital marketers and customer support teams.</p>
						</div>
						<CmsCard cardTitle='Convert Links into Marketing Assets' cardDescription='Get more organic shares and click-throughs with custom links. With FLO.ink, you can advertise to your audience directly and extract more value from the branded links you share. Get up to 35% more clicks.' />
						<CmsCard cardTitle='Insights with Real-Time Analytics' cardDescription='Know what to share with the audience. FLO.ink brings real-time analytics to help you maximize campaign performance. Get rid of the guesswork and know which type of content is resonating with your customers, and how clicks, shares, demographics, geolocation and top referring channels are performing.' />
						<CmsCard cardTitle='Integrate with Other Technologies' cardDescription='Connect your business and advertising tools to FLO.ink. It saves you time and hassle by powering up your work tools with branded links using the FLO.ink API. It gives you the freedom to share your branded links seamlessly from your favorite tools, apps and pages.' />
						<CmsCard cardTitle='Designed to Protect Your Data' cardDescription='FLO.ink helps incorporate branded links into your existing workflow, tools and processes with ease and security. Protect your brand image with safe and reliable shortened links, encrypted with HTTPS to guard against counterfeits.' />					
						<div className='cms-card-btns d-flex justify-content-center'>
							<Link to='/' className='btn btn-solid'>Get Started</Link>
							<Link to='/' className='btn btn-outline'>Get A Quote</Link>
						</div>
					</Container>
				</div>
				<div className='gray-background cms-card-holder'>
					<Container>
						<div className='cms-heading-style'>
							<h2>Integrations & API</h2>
							<span className='page-text d-block text-center'>Connect Our System To Yours</span>
						</div>
						<div className='cms-heading-style card-heading-style'>
							<h2 className='text-left'>Beat the Workflow Odds</h2>
							<p className='page-text'>Integrate and activate your preferred apps and extensions with FLO.ink to speed up your workspace performance and teammates productivity.</p>
						</div>
						<CmsCard cardTitle='Collaborate with Cohesive Strategy' cardDescription='Share branded short links across teams, departments and regions with one cohesive strategy. Connecting FLO.ink to the tools you use every day will scale up your work efficiency. Setup database where team members can connect to view, manage and monitor the branded links, custom domains, social media, promotional campaigns and customer experience.' />
						<CmsCard cardTitle='High Volume Links with Less Hassle' cardDescription='Access dedicated API endpoints to securely create and manage high quantities of branded links at scale. Streamline your link shortening capabilities You can set links to auto-expire and benefit from a specific pricing model for maximum efficiency.' />
						<CmsCard cardTitle='All URLs Under HTTPS Protocol' cardDescription='FLO.ink’s enterprise-grade data security backed by 2FA and SSL security tools allows to confidently integrate your workflow with other technologies. Https short links and 100% secure click data are mandatory for proximity marketing and referral data in analytics.' />
						<CmsCard cardTitle='Deliver Short Links via SMS in Bulk' cardDescription='With FLO.ink API, you can deliver a bulk of short links via SMS. Integrate this powerful API with world’s leading SMS communication platforms like Twilio to send out branded messages in high volume to your target audience.' />					
						<div className='cms-card-btns d-flex justify-content-center'>
							<Link to='/' className='btn btn-solid'>Get Started</Link>
							<Link to='/' className='btn btn-outline'>Get A Quote</Link>
						</div>
					</Container>
				</div>
				<div className='cms-card-holder'>
					<Container>
						<div className='cms-heading-style'>
							<h2>Enterprise Class</h2>
							<span className='page-text d-block text-center'>Get More with Less</span>
						</div>
						<div className='cms-heading-style card-heading-style'>
							<h2 className='text-left'>Automate Complex Business Workflows</h2>
							<p className='page-text'>Businesses go through several inflection points, where an enterprise solution partner can provide valuable guidance and programs to take brand growth and expansion to the next level.</p>
						</div>
						<CmsCard cardTitle='Powerful' cardDescription='FLO.ink API helps businesses personalize and track thousands of links per minute, at scale. You can run cross-channel campaigns using lots of branded links. No matter traffic volume your business generates, our system can handle it efficiently.' />
						<CmsCard cardTitle='Reliable' cardDescription='Designed for ease of use, FLO.ink API delivers guaranteed service uptime. It is the most advanced technology, which functions as a world-class infrastructure that is set up to grow with your business.' />
						<CmsCard cardTitle='24/7 Security' cardDescription='FLO.ink’s support teams monitor the availability and performance of your branded short links and domains, round-the-clock. All the short links and clicks metrics is well-protected under https, SSL and 2FA. Internal audits and 24/7 human monitoring helps keep your data 100% private and secure.' />
						<CmsCard cardTitle='Flexible' cardDescription='Create thousands of new branded links per second with rapid autoscaling server clusters. FLO.ink delivers a higher level of intelligence, security and customer experience when it comes to shortened links and custom domains. Hundreds of developers already using FLO.ink API to manage their dedicated projects.a' />					
						<div className='cms-card-btns d-flex justify-content-center'>
							<Link to='/' className='btn btn-solid'>Get Started</Link>
							<Link to='/' className='btn btn-outline'>Get A Quote</Link>
						</div>
					</Container>
				</div>
			</div>
			<Footer/>
		</div>
	)
}

export default WhyFloInk