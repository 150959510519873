import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom"
import { Images } from '../../assets/assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPhoneVolume, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css'

function Footer() {
    return (
        <div className='footer'>
            <div className='footer-bg'>
                <Container>
                    <Row>
                        <Col>
                            <div className='power-Link d-md-flex justify-content-between align-items-center'>
                                <h2>Start sharing powerful links</h2>
                                <Link className="btn btn-solid" to="/signup">Get Started</Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='main-footer'>
                                <div className='footer-logo d-flex justify-content-center align-items-center'>
                                    <strong>
                                        <Link className="navbar-brand d-flex" to="/">
                                            <img className='img-fluid' src={Images.footerLogo} alt="Site Logo" />
                                        </Link>
                                    </strong>assets
                                </div>
                                <ul className='unorder-icon p-0 d-flex justify-content-center align-items-center'>
                                    <li>
                                        <a href='https://www.facebook.com' className='footer-icon d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.google.com' className='footer-icon d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faGooglePlusG} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://twitter.com' className='footer-icon d-flex justify-content-center align-items-center'>

                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.linkedin.com' className='footer-icon d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                        </a>
                                    </li>
                                </ul>
                                <ul className='ps-0 contactUS-list d-flex justify-content-center align-items-center'>
                                    <li className='d-flex justify-content-center align-items-center'>
                                        <Link>
                                            <FontAwesomeIcon icon={faPhoneVolume} />
                                        </Link>
                                        <a href="/tel:+92 221 4413 111">+92 221 4413 111</a>
                                    </li>
                                    <li className='d-flex justify-content-center align-items-center'>
                                        <div>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </div>
                                        <a href="/mailto:support@flo.ink">support@flo.ink</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='bg-blue'>
                <Container>
                    <div className='d-md-flex justify-content-between align-items-center'>
                        <p>Copyright 2021 &copy; Flo.ink  | All Rights Reserved</p>
                        <p className='mb-0'>Design &amp; Developed By Arhamsoft Pvt Ltd.</p>
                    </div>
                </Container>
            </div>
        </div>
    );
}
export default Footer;