import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Images } from '../../../assets/assets'
import '../../Auth/Auth.css'

function LogIn() {
	return (
		<div className='auth-page d-flex flex-column align-items-center justify-content-center'>
			<div className='auth-container mx-auto d-flex flex-column align-items-center justify-content-center'>
				<strong className='auth-logo-strong'>
					<Link to='/' className='d-inline-block'>
						<img src={Images.siteLogo} alt='' />
					</Link>
				</strong>
				<h3 className='auth-heading'>Sign in & Start Sharing</h3>
				<Form className='auth-form'>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Control type="email" placeholder="Email" required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Control type="password" placeholder="Password" required />
					</Form.Group>
					<Link to='/ForgetPassword' className='auth-form-link auth-span d-block'>Forgot Password?</Link>
					<Button className='auth-form-btn d-block' type="submit">Sign In</Button>
				</Form>
				<span className='auth-separator auth-span'>OR</span>
				<div className='signin-with'>
					<span className='signin-with-span auth-span d-block'>Sign in with:</span>
					<div className='social-sigin-in d-flex flex-wrap f'>
						<Link to='/' className='d-flex justify-content-center align-items-center'>
							<img src={Images.GoogleIcon} alt='' />
						</Link>
						<Link to='/' className='d-flex justify-content-center align-items-center'>
							<img src={Images.FacebookIcon} alt='' />
						</Link>
						<Link to='/' className='d-flex justify-content-center align-items-center'>
							<img src={Images.TwitterIcon} alt='' />
						</Link>
					</div>
				</div>
				<div className='switch-to-signup '>
					<span className='auth-span switiching-span'>Don't have an account? </span>
					<Link to='/SignUp'>Sign Up Now</Link>
				</div>
			</div>
		</div>
	)
}

export default LogIn