import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Images } from '../../assets/assets'
import Header from '../Header/Header'
import PageBanner from '../PageBanner/PageBanner'
import Footer from '../Footer/Footer'

function TermsOfServices() {
	return (
		<div className='cms-page'>
			<Header />
			<PageBanner bannerHeading='Terms Of Services' bannerSubHeading='Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.' bannerContentImg={Images.BannerContentImg} />
			<section className='cms-text position-relative'>
				<div className='page-content-bg'>
					<img src={Images.PageContentBg} alt='' />
				</div>
				<Container>
					<p className='page-text'>Flo.ink, Inc. (“Flo.ink,” “we” or “our”) provides URL shortening, custom-branded link creation, management and analytics to its users (collectively, termed as “Flo.ink Services”.)</p>
					<p className='page-text'>Please carefully read this agreement for our Terms of Services since they govern your access to and use of NED.link Services and constitutes a binding legal contract between you and this web portal NED.link. If you accept this Agreement or use our Services on behalf of any organization or legal entity, you represent and guarantee that you are authorize to bind that organization to this Agreement. Moreover, you warrant that in any event, you and your customers will refer and apply to that organization or legal entity. You also agree to abide by these Terms of Service (the Agreement) if you have been granted access directly or through an administrator to and use of our Services by and on behalf of any other primary account holder.  </p>
					<p className='page-text'>In addition to this Agreement, the access to and use of Flo.ink Services is governed by the Flo.ink Privacy Policy.</p>
					<p className='page-text'><strong>YOU ACKNOWLEDGE THAT, BY CREATING A FLO.INK ACCOUNT, ACCESSING OR USING THE FLO.INK SERVICES, OR PURCHASING A PAID FLO.INK ACCOUNT, YOU AFFIRM THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY FLO.INK TERMS OF SERVICES. IF YOU DO NOT COME TO AN AGREEMENT TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE FLO.INK SERVICES.</strong></p>
					<p className='page-text'><strong>THIS AGREEMENT CONTAINS AN ARBITRATION THAT YOU AGREE ABOUT DISPUTES BETWEEN US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PETITIONER OR CLASS MEMBER IN ANY ALLEGED CLASS ACTION OR REPRESENTATIVE PROCEEDING.</strong></p>
					<h3>SCOPE & MODIFICATIONS</h3>
					<ol>
						<li>
							<h3>Modifications.<p className='page-text'>Flo.ink in its sole discretion reserves the right to change or replace the Terms of Services at any time. We will post the updated Agreement on our website and may notify you about the modifications on your Flo.ink Account Details page. You are affirming your consent to be bound by the modified Terms by continuing access to and use of Flo.ink Services after the effective date of Agreement Modifications.</p><p className='page-text'>you are also indicating that you own the responsibility to periodically check this Agreement for changes and that its use of our Services following the posting of any changes to Flo.ink Terms constitutes acceptance of those changes. If the modified Agreement is not acceptable to you, your only recourse is to cease using the Flo.ink Services.</p></h3>
						</li>
						<li>
							<h3>Scope & Current Offerings.<span className='page-text'> . As of the Effective Date, Flo.ink offers certain FLO.INK SERVICES to its users. The Flo.ink owner/admin may add, remove, suspend, discontinue, modify or update any service at any time, at its discretion. After the effective date of such update, Flo.ink shall bear no obligation to provide or support legacy versions of the Services</span></h3>
						</li>
						<li>
							<h3>NED.link Enterprise Users. <span className='page-text'>If you have purchased a Flo.ink Enterprise Plan, these terms may be modified or supplemented by an additional enterprise agreement and one or more order forms, the terms of which will control to the extent they conflict with these Terms.</span></h3>
						</li>
					</ol>
					<h3>PAID ACCOUNTS</h3>
					<ol>
						<li>
							<h3>Fees. <span className='page-text'>  Flo.ink offers fee-based SERVICES that provide additional features and functionality. Pricing and services for paid accounts are described on our Pricing page and may be modified from time to time. If you sign up for a paid Flo.ink account, you agree to pay all applicable fees for the tier our SERVICES as per your selected subscription plan. Fees are non-refundable except as specifically permitted in this Agreement or otherwise as required by law.</span></h3>
						</li>
						<li>
							<h3>Auto-Renewals & Trials. <span className='page-text'> If your Flo.ink Account is in a trial period ort is set to auto-renew and you have already selected the online payment method to Flo.ink for the Services, your payment account linked to your Flo.ink Account will be automatically charged as the trial ends or beginning of each renewal term for your Flo.ink Account, unless you notify us that you want to disable or terminate auto-renewal before the expiration of the then-current term. You may terminate the Agreement in your account’s Account Details page, or, if you purchased your Service through a Flo.ink account representative by contacting your Flo.ink account representative. If you are on a paid account plan lasting a year or longer, we will notify you within 30 days before your Flo.ink Account renews, including any changes in the Fees for the plan.</span></h3>
						</li>
						<li>
							<h3>Payment.<span className='page-text'>  You will pay Flo.ink invoices on the payment interval selected. If not otherwise specified, payments will be due immediately. You also authorize Flo.ink to charge your payment account for all applicable Fees using your selected payment method through Flo.ink’s online payments platform. You must provide complete and accurate billing and contact information to Flo.ink. Flo.ink may suspend or terminate the Services if Fees are past due. Unpaid Fees are subjected to a finance charge of one percent (1.5%) per month (18% per annum), or the maximum permitted by law, whichever is lower, plus all expenses of collection, which may include attorneys’ fees.</span></h3>
						</li>
						<li>
							<h3>Downgrades. <span className='page-text'>Flo.ink reserves the right to downgrade, suspend or terminate Customer’s access to any or all FLO.INK SERVICES if Fees are past due.</span></h3>
						</li>
					</ol>
					<h3>INTELLECTUAL PROPERTY & LICENSE RESTRICTIONS</h3>
					<ol>
						<li>
							<h3>Ownership. <span className='page-text'> Except for any User Content or Customer Services, you acknowledge and agree that Flo.ink is the sole and exclusive owner of all right, title and interest in and to the SERVICES and Flo.ink Link Metrics and all related documentation, source codes, tools, scripts, processes, methodologies, inventions, know-how, concepts, formatting, arrangements, visual attributes, database rights, copyrights, patents, trade secrets, and other intellectual property, and all derivatives, enhancements, amendments and improvements thereof (“Flo.ink Materials”). Nothing in this Agreement or any other document shall be deemed to transfer ownership of the Flo.ink Materials. Except for the limited license rights expressly granted herein, no rights to Flo.ink Materials are granted hereunder and all rights in such Flo.ink Materials are reserved.</span></h3>
						</li>
						<li>
							<h3>Technical Restrictions.  <span className='page-text'> You shall not exceed the number and/or frequency of API calls; concurrent URL shortens or other access to or use of FLO.INK SERVICES in the relevant documentation or as otherwise provided by Flo.ink in an Order Form. If Flo.ink observes that a user has attempted to exceed or circumvent these limitations, Flo.ink may block or suspend his access to the Services. Flo.ink may monitor your use of the Services ensure your compliance with this Agreement.</span></h3>
						</li>
						<li>
							<h3>Third Party Materials.<span className='page-text'> FLO.INK SERVICES may utilize third party software or source code, including without limitation Open Source Software (“Third-Party Materials”). Flo.ink has no control over Third-Party Materials. Accordingly, Flo.ink is not responsible or liable for any Third-Party Materials. While Flo.ink has no obligation to monitor Third-Party Materials, Flo.ink may remove or modify any Third-Party Materials in its discretion, including without limitation to comply with Law. You need to agree to comply with all terms and conditions and privacy policies related to any Third-Party Materials.</span></h3>
						</li>
						<li>
							<h3>Trademarks.<span className='page-text'> Flo.ink grants Customer a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to access and use Flo.ink’s names, logos, designs, and other trademarks incorporated into the FLO.INK SERVICES (“Flo.ink Marks”) during the Term and solely for the purposes of displaying such notice as part of the Flo.ink Services. Customer recognizes the validity of the Flo.ink Marks and Flo.ink’s ownership and title thereto. Any goodwill derived from the use of the Flo.ink Marks by Customer shall inure to the benefit Flo.ink. Customer will not challenge the Flo.ink Marks, or Flo.ink’s ownership and title thereto, or the USPTO application or registration thereof, either during or subsequent to the term of this Agreement. Customer shall execute such documents as may be reasonably requested by Flo.ink, or required by law, to establish Flo.ink’s sole and exclusive ownership and rights in the Flo.ink Marks, or to obtain registration thereof. Customer agrees to modify the use of any Flo.ink Marks which do not meet Flo.ink’s standards. Notwithstanding the foregoing, Customer may not use any Flo.ink Marks in any manner implying any partnership with, sponsorship by, or endorsement by Flo.ink.</span></h3>
						</li>
					</ol>
					<h3>CUSTOMER SERVICES, CONTENT AND INFORMATION</h3>
					<ol>
						<li>
							<h3>Customer Services.  <span className='page-text'> Flo.ink shall have no liability for any Customer product or service accessed through or making use of the FLO.INK SERVICES or any end user, customer or Personnel’s use thereof (“Customer Service”). So, you shall not use the SERVICES in any manner implying any partnership with, sponsorship by, or endorsement of the Customer Service by Flo.ink. You shall not suggest or imply that Flo.ink is the author of or otherwise responsible for the views or content of the Customer Service. The FLO.INK SERVICES shall not be used in connection with any Prohibited Content, or any activities where the use or failure of the SERVICES could lead to death, personal injury or property or environmental damage or adversely impact or impose liability on Flo.ink in any manner.</span></h3>
						</li>
						<li>
							<h3>Customer Content.<span className='page-text'> Customer hereby grants to Flo.ink an irrevocable, perpetual, non-exclusive, sublicensable, transferable, royalty-free, worldwide license, to use, copy, import, exhibit, reproduce, perform, distribute, create derivative works, or modify all URLs and other information provided by Customer to Flo.ink (“Customer Content”) relating to the provision, operation and promotion of the Flo.ink Services, creation of Flo.ink Link Metrics and for other business purposes. Flo.ink’s collection, use and sharing of personal information Flo.ink receives from Customer or third parties (including social media networks) is described Flo.ink’s Privacy Policy. As stated in our Privacy Policy, where permitted by law, if you register a Flo.ink Account with an email address on a domain owned by an organization, such as your employer, we may share your email address and information about your Account with Flo.ink sales team and the business to explore the business’ interest in creating and/or managing an enterprise account or for related purposes.</span></h3>
						</li>						
					</ol>
					<h3>LIMITATION OF LIABILITY</h3>
					<p className='page-text'>In no event shall Flo.ink be liable under contract, tort, strict liability, negligence or any other legal or equitable theory relating to the Flo.ink Services, Flo.ink Link Metrics or otherwise hereunder for any claim related to;</p>
					<ol>
						<li className='page-text'>any lost profits, data loss, cost of procurement of substitute products or services, or special, indirect, incidental, punitive, compensatory, or consequential damages of any kind whatsoever (however arising)</li>
						<li className='page-text'>any malfunctional feature because of bugs, viruses, , or the like (regardless of the source of origination)</li>
						<li className='page-text'>any permanent or temporary cessation in the provision of the Flo.ink Services</li>
						<li className='page-text'>the deletion and corruption of, or failure to store, any Customer Content and other data maintained or transmitted by or through customer’s use of the Flo.ink Services</li>
						<li className='page-text'>customer’s failure to provide Flo.ink with accurate account or other information</li>
						<li className='page-text'>any liability resulting from customer’s failure to keep his Account details (such as password, etc.) secure and confidential</li>
						<li className='page-text'>customer’s failure to access the Flo.ink Services due to malfunction(s) in his equipment, infrastructure, system, or the network he uses to access and use our Services</li>
					</ol>
					<h3>TERM AND TERMINATION</h3>
					<ol>
						<li>
							<h3>Agreement Term. <span className='page-text'>This Agreement shall commence on the date the Customer create an account or purchasing a paid service tier (“Effective Date”) and remain in effect for the duration of the term selected by the Customer, unless terminated as permitted herein. If your paid account is set to automatically renew, please see above for the auto-renewal terms.</span></h3>
						</li>
						<li>
							<h3>Right to Terminate.  <span className='page-text'> Flo.ink may terminate this Agreement immediately in its discretion. As soon as this Agreement is expired or terminated, all applicable rights and access granted to Customer shall automatically terminate and Customer and its Personnel shall cease any further use of the FLO.INK SERVICES and return, or, if directed by Flo.ink, destroy, all Confidential Information of Flo.ink.   </span></h3>
						</li>
					</ol>
					<h3>GOVERNING LAW & DISPUTES</h3>
					<ol>
						<li>
							<h3>Governing Law.<span className='page-text'>This Agreement and the transactions contemplated hereby shall be governed by and construed under the country laws NED.link operates without regard to the conflicts of law provisions thereof and without regard to the United Nations Convention on Contracts for the International Sale of Goods, Products and Services.</span></h3>
						</li>
						<li>
							<h3>Limitation for Bringing Claims.<span className='page-text'> To the fullest extent permitted by law, Customer agrees any Customer claim or cause of action arising out of, related to or connected with the use of the Flo.ink Services, Flo.ink Link Metrics or this Agreement must be filed within one (1) year after such claim of action arose or be forever banned. </span></h3>
						</li>
						<li>
							<h3>Mandatory Arbitration of Disputes.<span className='page-text'> We each agree that any dispute, claim or controversy arising out of or relating to Flo.ink’s Terms of Service or the breach, termination, expiry, enforcement, interpretation or validity thereof or the use of the Flo.ink Services or Customer Content (collectively, “Disputes”) will be resolved solely by binding, individual settlement and not in a class, representative or combined action or proceeding. You and Flo.ink agree that the state law governs the interpretation and enforcement of these Terms, and that you and Flo.ink are each waiving the right to a trial by a legal panel or to participate in a class action.</span></h3>
						</li>
					</ol>
					<h3 >DISCLAIMERS</h3>
					<p className='page-text'>Flo.ink services are provided “as is” and “as available” without warranty of any kind, express or implied, including, but not limited to, the implied warranties of title, non-infringement, merchantability and suitability for a specific purpose, and any warranties implied by usage of trade, all of which are expressly disclaimed. Flo.ink does not warrant that:</p>
					<ol>
						<li className='page-text'>Flo.ink Services will be secure or available at any particular time or location</li>
						<li className='page-text'>shortened URLs, the Flo.ink Services and Flo.ink Link Metrics will be accurate, error-free or that any defects or errors will be corrected</li>
						<li className='page-text'>Flo.ink Services are secure, free of viruses or other harmful components</li>
						<li className='page-text'>results of using the Flo.ink Services or Flo.ink Link Metrics will meet customer’s requirements or any of its or its users’, personnel’s or customers’ business needs</li>
						<li className='page-text'>Flo.ink Services will be uninterrupted or that any interruption will be corrected in a timely manner.</li>
					</ol>
					<p className='page-text'>Customers use of the Flo.ink Services and Flo.ink Link Metrics is solely at its own risk. Further, Flo.ink makes no representations or warranties and shall assume no liability amounts or indemnity obligations with respect to ensuring that customer’s use of the Flo.ink Services and Flo.ink Link Metrics comply with any laws or regulations outside the united states and customer shall be solely liable for such compliance. Flo.ink shall bear no responsibility for third-party products or services (e.g., open source software, social network platforms, third-party materials or for host or app store providers). Customer recognizes that the figures contained in the Flo.ink Link Metrics produced hereunder are estimates and may be subject to statistical error. Flo.ink does not warrant that the Flo.ink Link Metrics will be completely accurate, timely or otherwise reliable. Flo.ink hereby disclaims any liability for any use or reliance on the Flo.ink Link Metrics by customer and third-party service providers.</p>
					<p className='page-text'>If you have any questions about our Terms of Service, please contact at <Link to='/'>support@flo.ink.com</Link> </p>
				</Container>
			</section>
			<Footer/>
		</div>
	)
}

export default TermsOfServices