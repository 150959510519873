import React from 'react'
import { Container } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from "react-router-dom"
import './Banner.css'

function Banner() {
    return (
        <div className='banner'>
            <Container>
                <div className='main-banner position-relative'>
                    <div className='banner-heading position-relative'>
                        <span className='text-white d-flex position-relative'>Increases your brand engagement and exposure</span>
                        <h1 className='text-white'>Rocket <strong className='d-flex justify-content-center align-items-center'> your Business</strong></h1>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className='text-white d-flex mb-0 position-relative'>Build and boost a whole new brand identity using powerful, recognizable, click-worthy short links.</span>
                        </div>
                    </div>
                    <div className='banner-img position-absolute'>
                        <img className='img-fluid' src={Images.bannerImg} alt="Site Logo" />
                    </div>
                </div>
                <div className='banner-input position-relative'>
                    <from >
                        <div className='input-skew d-sm-flex align-items-center'>
                            <InputGroup className="">
                                <Form.Control
                                    className='w-100'
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            <div className='ms-2'>
                                <button className='btn-white'>Shorten</button>
                            </div>
                        </div>
                    </from>
                    <p className='text-white'>By clicking SHORTEN, you are agreeing to FLO.ink’s <Link to="/">Privacy Policy</Link> and <Link to="/">Terms of Service</Link></p>
                </div>
            </Container>
        </div>
    );
}
export default Banner;